export default [
  {
    key: 'no',
    label: 'field.shipmentNo',
    type: 'text',
    operator: '%',
  },
  {
    key: 'orderNo',
    label: 'field.orderNo',
    type: 'text',
    operator: '%',
  },
  {
    key: 'outletId',
    label: 'field.outlet',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'outlet',
    selectionKey: 'id',
    selectionLabel: 'label',
    localization: true,
  },
  {
    key: 'postingDate',
    label: 'field.shipmentDate',
    type: 'date-range-picker',
    cast: 'range',
    firstOperator: 'd>=',
    secondOperator: 'd<=',
  },
  {
    key: 'createdAt',
    label: 'field.createdDate',
    type: 'date-range-picker',
    cast: 'range',
    firstSuffix: "00:00:00",
    secondSuffix: "23:59:59",
    firstOperator: 'dt>=',
    secondOperator: 'dt<=',
  },
];
