export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    stickyColumn: true,
    variant: 'light',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'no',
    label: 'field.shipmentNo',
    hideToggle: true,
  },
  {
    key: 'orderNo',
    label: 'field.orderNo',
    resource: 'agent-sales-order',
    route: 'view-sales-order',
    routeParam: 'salesOrderId',
    hideToggle: true,
  },
  {
    key: 'postingDate',
    label: 'field.shipmentDate',
    type: 'date',
    hideToggle: true,
  },
  {
    key: 'customerName',
    subKey: 'customerCode',
    label: 'field.outlet',
    hideToggle: true,
  },
  {
    key: 'customerAddress',
    label: 'field.address',
    hideToggle: true,
  },
  {
    key: 'paymentMethodCode',
    label: 'field.paymentMethod',
    hideToggle: true,
  },
  {
    key: 'createdAt',
    label: 'field.createdDate',
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
  },
];
